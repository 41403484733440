// extracted by mini-css-extract-plugin
export var heroContainer = "homestyles-module--heroContainer--sDh1n";
export var intro = "homestyles-module--intro--BYx6g";
export var allProjects = "homestyles-module--allProjects--b0+Ik";
export var portfolioContainer = "homestyles-module--portfolioContainer--h+KmS";
export var underline = "homestyles-module--underline--ab9r8";
export var projectGrid = "homestyles-module--projectGrid--2+1jJ";
export var small = "homestyles-module--small--P2hU6";
export var sizing = "homestyles-module--sizing--MPa5s";
export var featuredProject = "homestyles-module--featuredProject--oxfXN";
export var image = "homestyles-module--image--4cdVu";
export var description = "homestyles-module--description--OQS5w";
export var caseStudy = "homestyles-module--caseStudy--4o0I2";
export var smallProject = "homestyles-module--smallProject--sQbg0";
export var iconStyle = "homestyles-module--iconStyle--vo-Iv";
export var divider = "homestyles-module--divider--3CiGM";